<template>
  <div class="">
    <h2>Settings</h2>

    <accountComponent />

    <emailComponent :user="userDetail" />

    <phoneComponent :user="userDetail" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import validationMxn from '@/mixins/validation_mixin';

export default {
  name: "Settings",
  mixins: [validationMxn],
  components: {
    accountComponent: () =>
      import("@/modules/Settings/components/accountComponent"),
    emailComponent: () =>
      import("@/modules/Settings/components/emailComponent"),
    phoneComponent: () => import("@/modules/Settings/components/phoneComponent")
  },
  data() {
    return {
      userDetail: '',
    }
  },
  computed: {
    ...mapGetters(['getSession'])
  },
  mounted() {
    this.prefill()
  }, 
  methods: {
    async prefill() {
      const sessionData = this.getSession;
      const fullPayload = {
        params: {},
        endpoint: `/auth/user-show/${sessionData.id}`
      };

      const response = await this.performGetActions(fullPayload);
      this.userDetail = response.data
    }
  },
};
</script>

<style scoped>
.card-header {
  background: #232c3b;
  height: 70px;
  border: 2px solid #273142;
  border-radius: 4px;
}

.icon {
  width: 70px;
  text-align: left;
}

.sidebar {
  position: fixed;
}

.main {
  height: 600px;
  overflow-y: scroll;
}

.main::-webkit-scrollbar {
  display: none;
}
</style>
